import { html, LitElement } from "lit"
import { msg, str, updateWhenLocaleChanges } from "@lit/localize"
import { setLocale } from "#js/components/lit-i18n"

export class FullscreenToggle extends LitElement {
  static properties = {
    targetElementId: { type: String },
    btnClass: { type: String },
  }

  constructor() {
    super()
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)
  }

  render() {
    if ("exitFullscreen" in document) {
      return html`
        <link rel="stylesheet" href="${globalThis.styleFilePath}"/>
        <ui-button class=${this.btnClass || "tonal"} @click="${this.toggleFullscreen}">
          ${msg(str`full screen`)}
        </ui-button>
      `
    }
  }

  toggleFullscreen() {
    const elem = document.getElementById(this.targetElementId)
    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        console.error(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`,
        )
      })
    } else {
      document.exitFullscreen()
    }
  }
}

globalThis.customElements.define("fullscreen-toggle", FullscreenToggle)
